import React from 'react'
import Box from '@material-ui/core/Box'
import FormPage from '../../components/GdForm'
import HubSpotForm from '../../components/HubSpot'

const Hsform = (props) => {
    const data = props.data[props.componentId]
    const height = data.height
    const width = data.width

    const anchorLink = Boolean(data.anchor)

    const hasGDlink = Boolean(data.googleDocumentLink)
    const hasHSlink = Boolean(data.hubSpotLink)

    return (
        <Box>
            {anchorLink && <Box id={data.anchor}></Box>}
            {/*<h1>{data.entryTitle}</h1> */}
            {hasGDlink && (
                <Box>
                    <FormPage height={height} width={width} data={data} />
                </Box>
            )}
            {hasHSlink && (
                <Box pb={3}>
                    <HubSpotForm width={width} data={data} />
                </Box>
            )}
        </Box>
    )
}

export default Hsform
