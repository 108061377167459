import React from 'react';
import Box from '@material-ui/core/Box';
import { useHubspotForm } from '@aaronhayes/react-use-hubspot-form';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = props =>
  makeStyles(theme => ({
    boxbox:{
      position:'relative',
      marginLeft:'auto',
      marginRight:'auto',
      width: `${props.width}%`,
      [theme.breakpoints.down('sm')]: {
        width:'80%',
          },
    },
    
}));

const HubSpotForm = (props) => {
  const data = props.data;
  const classes = useStyles({ ...props, data })();

  const portalId = data.portalId;
  const formId = data.formId;

useHubspotForm({
      portalId: `${portalId}`,
      formId: `${formId}`,
        target: '#my-hubspot-form',
    });


    return (
        <Box>
            <div className={classes.boxbox} id="my-hubspot-form"></div>
        </Box>
    )
}

export default HubSpotForm;