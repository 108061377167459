import React from 'react'
import Box from '@material-ui/core/Box'
import { Widget } from 'react-typeform-embed'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = (props) =>
    makeStyles((theme) => ({
        boxbox: {
            position: 'relative',
            marginTop: '0px',
            marginLeft: 'auto',
            marginRight: 'auto',
            height: `${props.height}em`,
            width: `${props.width}%`,
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
        },
    }))

const FormPage = (props) => {
    const data = props.data
    const classes = useStyles({ ...props, data })()
    const link = data.googleDocumentLink

    return (
        <Box className={classes.boxbox}>
            <Widget id={link} height={800} />
        </Box>
    )
}

export default FormPage
